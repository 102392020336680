<template>
  <div class="container-fluid">
    <h5 class="mb-3 text-primary fw-bold">
      Consultations
      <span v-if="!loading && pagination">({{ pagination.total }})</span>
    </h5>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-3 ms-auto my-auto">
            <input
              class="form-control form-control-sm onboarding-tour-step-6"
              type="text"
              v-model="searchTerm"
              @input="searchTermUpdate"
              placeholder="Search for clients, orders and more..."
              aria-label="Search for clients, orders and more..."
            />
          </div>
          <div class="col-auto my-auto">
            <select-user
              v-if="$can('view consultations for other users')"
              v-model="filterUser"
            />
          </div>
          <div class="col-auto my-auto">
            <select v-model="filterMethod" class="form-control form-control-sm">
              <option value="" selected disabled>Filter Method</option>
              <option value="standard">In Person</option>
              <option value="video">Video</option>
              <option value="phone">Phone</option>
            </select>
          </div>
          <div class="col-auto my-auto">
            <select
              v-model="filterPaymentStatus"
              class="form-control form-control-sm"
            >
              <option value="" selected disabled>Filter Payment Status</option>
              <option value="paid">Paid</option>
              <option value="partial">Partial</option>
              <option value="not_paid">Not Paid</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          <div class="col-auto">
            <select v-model="sortBy" class="form-control form-control-sm">
              <option value="" selected disabled>Sort By</option>
              <option value="id">ID</option>
              <option value="consultation_date">Consultation Date</option>
            </select>
          </div>
          <div class="col-auto">
            <select
              v-model="sortDirection"
              class="form-control form-control-sm"
            >
              <option value="" selected disabled>Sort Direction</option>
              <option value="desc">Newest first</option>
              <option value="asc">Oldest first</option>
            </select>
          </div>
          <div class="col-auto my-auto">
            <button class="btn btn-outline-primary btn-sm" @click="clearFilters">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body bg-white">
        <loading
          v-if="loading || searchTermCheckerActive"
          class="my-4"
        ></loading>

        <table
          class="table"
          v-if="
            consultations.length > 0 && !loading && !searchTermCheckerActive
          "
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" v-if="$can('view consultations for other users')">
                Practitioner
              </th>
              <th scope="col">Client</th>
              <th scope="col">Delivery</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Total Cost</th>
              <th scope="col">Consultation Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="loadingConsultations">
            <tr>
              <td colspan="10" class="text-center">
                <busy />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="c in consultations"
              :key="c.id"
              class="cursor-pointer"
              @click="goToConsultation(c.id)"
            >
              <td>#{{ c.id }}</td>
              <td
                v-if="$can('view consultations for other users')"
                class="text-capitalize"
              >
                {{ c.practitioner_first_name }}
                {{ c.practitioner_last_name }}
              </td>
              <td class="text-capitalize">
                {{ c.client_first_name }} {{ c.client_last_name }}
              </td>
              <td class="text-capitalize">{{ c.method | formatMethod }}</td>
              <td class="text-capitalize">
                <span
                  class="badge badge-pill text-capitalize"
                  :class="getConsultationStatusColour(c.order_status)"
                >
                  {{ c.order_status | formatUnderScored }}</span
                >
              </td>
              <td>{{ c.symbol }}{{ c.price.toFixed(2) }}</td>
              <td>
                {{ c.event_start | formatDateTime }}
              </td>

              <td style="width: 1px; white-space: nowrap">
                <span v-if="c.is_overdue" class="badge bg-danger ms-3"
                  >Payment Overdue</span
                >
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="row my-4 text-center"
          v-if="
            consultations.length == 0 && !loading && !searchTermCheckerActive
          "
        >
          <div class="col">
            <h5>There are no consultations using that filter</h5>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col my-auto">
                <button
                  class="btn btn-sm btn-light"
                  @click="fetchConsultations(pagination.first_page_url)"
                  :disabled="!pagination.first_page_url"
                >
                  First Page
                </button>

                <button
                  class="btn btn-sm btn-light ms-3"
                  @click="fetchConsultations(pagination.prev_page_url)"
                  :disabled="!pagination.prev_page_url"
                >
                  Previous
                </button>
              </div>
              <div class="col my-auto text-center">
                <p class="mb-0">
                  Page
                  {{ pagination.current_page ? pagination.current_page : 1 }}
                  of
                  {{ pagination.last_page ? pagination.last_page : 1 }}
                </p>
              </div>
              <div class="col my-auto text-end">
                <button
                  class="btn btn-sm btn-light cursor-pointer me-3"
                  @click="fetchConsultations(pagination.next_page_url)"
                  :disabled="!pagination.next_page_url"
                >
                  Next
                </button>

                <button
                  class="btn btn-sm btn-light cursor-pointer"
                  @click="fetchConsultations(pagination.last_page_url)"
                  :disabled="!pagination.last_page_url"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";
import SelectUser from "@/views/components/users/SelectUser";

export default {
  components: { Loading, SelectUser },
  props: ["user"],
  data() {
    return {
      loading: true,
      loadingConsultations: false,
      consultations: [],
      pagination: {},
      searchTerm: "",
      searchTermCheckerActive: false,
      sorting: "",
      filterMethod: "",
      filterPaymentStatus: "",
      filterUser: "",
      sortBy: "",
      sortDirection: "desc",
    };
  },
  watch: {
    filterMethod() {
      this.fetchConsultations();
    },
    filterPaymentStatus() {
      this.fetchConsultations();
    },
    sortBy() {
      this.fetchConsultations();
    },
    sortDirection() {
      this.fetchConsultations();
    },
    filterUser() {
      this.fetchConsultations();
    },
  },
  methods: {
    searchTermUpdate() {
      if (!this.searchTermCheckerActive) {
        this.fetchConsultationsBasedOnSearchTerms();
        this.searchTermCheckerActive = true;
      }
    },
    fetchConsultationsBasedOnSearchTerms() {
      setTimeout(() => {
        const seconds = new Date().getSeconds();
        if (seconds % 2 === 0) {
          this.searchTermCheckerActive = false;
          this.fetchConsultations();
        } else {
          this.fetchConsultationsBasedOnSearchTerms();
        }
      }, 1000);
    },
    goToConsultation(id) {
      this.$router.push("/consultations/" + id);
    },
    fetchConsultations(paginationUrl) {
      this.loadingConsultations = true;
      const url = new URL(
        paginationUrl
          ? paginationUrl
          : process.env.VUE_APP_API_URL + "/consultations/api/index"
      );

      if (this.filterPaymentStatus) {
        url.searchParams.append("paymentStatus", this.filterPaymentStatus);
      }

      if (this.filterMethod) {
        url.searchParams.append("method", this.filterMethod);
      }

      if (this.filterUser) {
        url.searchParams.append("practitioner", this.filterUser.id);
      }

      if (this.sortDirection) {
        url.searchParams.append("sortDirection", this.sortDirection);
      }

      if (this.sortBy) {
        url.searchParams.append("sortBy", this.sortBy);
      }

      if (this.searchTerm) {
        url.searchParams.append("searchTerm", this.searchTerm);
      }

      this.$axios.get(url.toString()).then(({ data }) => {
        this.pagination = data;
        this.consultations = data.data;
        this.loading = false;
        this.loadingConsultations = false;
      });
    },
    getConsultationStatusColour(status) {
      if (status === "paid") {
        return "bg-success";
      } else if (status === "not_paid" || status === "partial") {
        return "bg-warning text-dark";
      } else if (status === "cancelled") {
        return "bg-danger";
      }

      return "bg-dark";
    },
    clearFilters() {
      this.filterPaymentStatus = "";
      this.filterMethod = "";
      this.sortBy = "";
      this.sortDirection = "";
      this.filterUser = null;
    },
  },
  mounted() {
    this.fetchConsultations();
  },
  filters: {
    formatDateTime(date) {
      if (date) {
        return moment.utc(date).local().format("LLL");
      } else {
        return "";
      }
    },
    formatUnderScored(text) {
      if (text != null) {
        return text.replace(/_/g, " ");
      }
      return "-";
    },
    formatMethod(str) {
      if (str === "standard") {
        return "In Person";
      } else {
        return str;
      }
    },
  },
};
</script>

<style>
</style>
